@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.container {
  position: fixed;
  left: 20%;
  bottom: 10vh;
  width: 60%;
  background-color: $clr-white;
  display: flex;
  height: size-rem(114px);
  justify-content: space-between;
  z-index: 10000000000;
  align-items: center;
  font-weight: 600;
  line-height: 120%;

  a {
    color: $clr-black;
  }

  @include vp--740 {
    left: 2%;
    width: 96%;
    height: size-rem(120px, true);
  }
}

.text {
  width: 75%;
  flex-direction: column;
  margin-left: size-rem(32px);
  font-weight: 350;
  font-size: size-rem(18px);
  line-height: 140%;
  font-family: $main-font-family;

  span {
    text-decoration: underline;
    text-underline-offset: size-rem(5px);
  }
  @include vp--740 {
    font-size: size-rem(14px, true);
    width: 65%;
    margin-left: size-rem(13px, true);
  }
}

.btn {
  cursor: pointer;
  padding: size-rem(14px) size-rem(32px);
  margin: size-rem(14px) size-rem(32px) size-rem(14px) 0;

  @include vp--740 {
    font-size: size-rem(16px, true);
    padding: size-rem(14px, true) size-rem(22px, true);
  }
}
