@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/responsive.scss';

.buttonBlack {
  position: relative;
  color: $clr-white;
  background: $clr-black;
  border: none;
  border-radius: size-rem(37px);
  font-family: $primary-font-family;
  cursor: pointer;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 140%;
  letter-spacing: -0.01em;
  transition: all 0.5s ease;

  &:hover {
    background: $clr-darker-grey;
    border: 1px solid $clr-darker-grey;
  }

  &:active {
    background: $clr-middle-grey;
    border: 1px solid $clr-middle-grey;
  }

  &:disabled {
    background: $clr-light-grey;
    color: $clr-dark-grey;
    border: 1px solid $clr-light-grey;
  }

  @include vp--740 {
    font-size: size-rem(18px, true);
    border-radius: size-rem(37px, true);
    transition: all 0.5s ease;
    &:hover {
      background: $clr-dark-grey;
      color: $clr-white;
      border: none;
    }

    &:hover span {
      color: $clr-white;
    }
  }
}
