@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.modal {
  padding: 0;
  margin: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999999999;

  background-color: rgba(28, 29, 31, 0.8);
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  border: none;
  outline: none;

  iframe {
    width: 100%;
    height: 100%;
  }

  @include vp--740 {
    width: 100%;
    height: size-rem(335px, true);
  }
}

.closeBtn {
  cursor: pointer;
  display: flex;
  top: size-rem(40px);
  right: size-rem(40px);
  position: fixed;

  background: transparent;
  outline: none;
  padding: 0;
  z-index: 100;
  border: none;

  transition: all 0.13s ease-in;

  @include vp--740 {
    top: size-rem(20px, true);
    right: size-rem(20px, true);
    width: size-rem(36px, true);
    height: size-rem(36px, true);
  }

  &:hover {
    opacity: 0.8;
    transform: rotate(90deg);
    transition: all 0.13s ease-in;
  }
}

.svg {
  width: size-rem(50px);
  height: size-rem(50px);

  @include vp--740 {
    width: 100%;
    height: 100%;
  }
}
