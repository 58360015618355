@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.modalContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  @include vp--740 {
    z-index: 99999;
  }
}

.modalBack {
  position: absolute;
  background: #1c1c1c;
  opacity: 0.9;
  width: 100%;
  height: 100%;
}

.modalBox {
  position: absolute;
  width: size-rem(500px);
  @include flex-column;
  align-items: center;
  top: 35%;
  left: 35%;
  height: size-rem(350px);
  background-color: $clr-white;
  padding-right: size-rem(26px);
  padding-left: size-rem(26px);
  box-sizing: border-box;
  @include vp--740 {
    width: size-rem(351px, true);
    height: size-rem(306px, true);
    padding-right: size-rem(26px, true);
    padding-left: size-rem(26px, true);
    top: 15%;
    left: 8%;
  }
}

.modalBtn {
  position: absolute;
  right: size-rem(0px);
  top: size-rem(0px);
}

.modalTitle {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  width: 60%;
  text-align: center;
  font-size: size-rem(40px);
  line-height: 110%;
  letter-spacing: -0.02em;
  color: $clr-black;
  opacity: 0.9;
  margin-bottom: size-rem(16px);
  margin-top: size-rem(32px);
  @include vp--740 {
    margin-bottom: size-rem(16px, true);
    margin-top: size-rem(32px, true);
    font-size: size-rem(32px, true);
  }
}

.modalText {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  width: 100%;
  font-size: size-rem(18px);
  line-height: 140%;
  text-align: center;
  color: $clr-black;
  opacity: 0.9;
  position: relative;
  margin-bottom: size-rem(30px);
  @include vp--740 {
    margin-bottom: size-rem(32px, true);
    font-size: size-rem(16px, true);
  }
}

.modalBtnClose {
  width: size-rem(130px);
  height: size-rem(50px);
  span {
    font-weight: 350 !important;
    font-size: size-rem(16px) !important;
  }

  @include vp--740 {
    width: size-rem(115px, true);
    height: size-rem(50px, true);
    span {
      font-weight: 350 !important;
      font-size: size-rem(16px, true) !important;
    }
  }
}
